export const questionsArray = {
  'it': [
    {
      isExam: true,
      questionText: 'Sei un cittadino italiano?',
      answerOptions: [
        { value: 'Sì', id: 1 },
        { value: 'No', id: 2, isCorrect: false },
      ],
      img: 'q'
    },
    {
      questionText: 'Qual è la tua età ?',
      answerOptions: [
        { value: '18-25', id: 1 },
        { value: '25-35', id: 2 },
        { value: '35-45', id: 3 },
        { value: '45+', id: 4 },
      ],
      img: 'a'
    },
    {
      questionText: 'Il tuo obiettivo finanziario ?',
      answerOptions: [
        { value: 'Provvedere a se stessi e alla famiglia', id: 1 },
        { value: 'Crea un cuscino finanziario per un "giorno di pioggia"', id: 2 },
        { value: 'Abbellisci / acquista un alloggio personale', id: 3 },
        { value: 'Essere finanziariamente indipendenti', id: 4 },
      ],
      img: 'b'
    },
    {
      questionText: 'Dove pensi di spendere i tuoi primi soldi guadagnati ?',
      answerOptions: [
        { value: 'Rimborsare prestiti / mutui / debiti', id: 1 },
        { value: 'Comprerò ciò che ho sempre sognato', id: 2 },
        { value: 'In viaggio', id: 3 },
        { value: 'Li investirò di nuovo per aumentare il capitale', id: 4 },
      ],
      img: 'c'
    },
    {
      questionText: 'Quanto tempo sei disposto a dedicare al guadagno ?',
      answerOptions: [
        { value: 'Poche ore al giorno', id: 1 },
        { value: 'Tutto il giorno', id: 2 },
        { value: 'Un giorno alla settimana', id: 3 },
        { value: 'Un giorno al mese', id: 4 },
      ],
      img: 'd'
    },
    {
      questionText: 'Con quale importo vorresti iniziare a fare trading ?',
      answerOptions: [
        { value: '€200', id: 1 },
        { value: '<span>€500</span> <i class="fa-solid fa-star ms-1 medium"></i><sup class="fst-italic" style="font-size: 12px; border-top: outset"> Più popolare</sup>', id: 2 },
        { value: '€750', id: 3 },
        { value: '€1000', id: 4 },
      ],
      img: 'e'
    },
    {
      questionText:
          'Sei pronto a investire nel trading? Dopotutto, come sai, per ottenere qualcosa, devi investire qualcosa',
      answerOptions: [
        { value: 'Sì, Pronto', id: 1 },
        { value: 'Mi piacerebbe, ma non sono sicuro che ce la farò', id: 2 },
        { value: 'Sto già investendo e ricevendo dividendi', id: 3 },
      ],
      img: 'f'
    },
  ],
  'en': [
    {
      isExam: true,
      questionText: 'Are you an citizen of ',
      answerOptions: [
        { value: 'Yes', id: 1 },
        { value: 'No, I live in this country for work/study/vacation', id: 2, isCorrect: false },
      ],
      // img: q,
      img: 'q',
    },
    {
      questionText: 'What is your age?',
      answerOptions: [
        { value: '18-25', id: 1 },
        { value: '25-35', id: 2 },
        { value: '35-45', id: 3 },
        { value: '45+', id: 4 },
      ],
      // img: a,
      img: 'a',
    },
    {
      questionText: 'How much money are you making now?',
      answerOptions: [
        { value: 'Less than €3000', id: 1 },
        { value: 'From €3000 to €5000', id: 2 },
        { value: 'From €5000 to €10000', id: 3 },
        { value: 'More than 10000€', id: 4 },
      ],
      // img: g,
      img: 'g',
    },
    {
      questionText: 'Imagine that you can fulfill your financial dream: What kind of dream will it be?',
      answerOptions: [
        { value: 'Provide for myself and my family', id: 1 },
        { value: 'Create a financial cushion for a "rainy day"', id: 2 },
        { value: 'Improve / purchase personal house', id: 3 },
        { value: 'Become financially independent', id: 4 },
        { value: 'Other', id: 4 },
      ],
      // img: b,
      img: 'b',
    },
    {
      questionText: 'What will the first money earned from trading be used for?',
      answerOptions: [
        { value: 'To pay off debts', id: 1 },
        { value: 'I\'ll buy what I\'ve always dreamed of', id: 2 },
        { value: 'Journeys', id: 3 },
        { value: 'I\'ll invest them to increase my capital', id: 4 },
      ],
      // img: c,
      img: 'c',
    },
    {
      questionText: 'How much time per day are you willing to devote to trading?',
      answerOptions: [
        { value: 'Several hours a day', id: 1 },
        { value: 'All day', id: 2 },
        { value: 'One day a week', id: 3 },
        { value: 'One day per month', id: 4 },
      ],
      // img: d,
      img: 'd',
    },
    {
      questionText: 'How much money are you willing to invest to start earning?',
      answerOptions: [
        { value: '€200', id: 1 },
        { value: '€600', id: 2 },
        { value: '€1000', id: 3 },
        { value: '€2000', id: 4 },
        { value: 'More than €2000', id: 5 },
      ],
      // img: e,
      img: 'e',
    },
    {
      questionText: 'How do you imagine your success in trading and what steps are you ready to take to achieve it?',
      answerOptions: [
        { value: 'I am ready to make every effort, no matter how quickly I succeed in a week or a day', id: 1 },
        { value: 'I would like to try, but I am not sure of myself, I hope that experienced consultants will help me, even if it takes some time', id: 2 },
        { value: 'I am already investing and receiving dividends and I want to try a new platform, I am sure I will quickly increase my capital', id: 3 },
      ],
      // img: f,
      img: 'f',
    },
  ]

};
