import {useCookie} from "./useCookie";
import {useDispatch, useSelector} from "react-redux/es/exports";
import {checkParam, delParams} from "../utils/utils";
import {writeCookie} from "../utils/cookie";
import {switchPreland} from "../redux/actions";
import {defaultLang, initPreLandState, langListSupported} from "../store/config";

export const useUrlParams = () => {
    const [cookieValues, updateCookie] = useCookie(
            ['g','gt','f','t','bi','d','b', 'up','getCountry2', 'getCountryName',  'getCity',   'getStateProv', 'gtmOffset', 'fbclid','w_src','w_ref' ],
        ['0','0', '0','0', '0','0','0', '0', 'false',       'false',           'false',     'false',         'false',    '0',     'false','false' ]
    );
    const urlParams = useSelector((state) => state.urlParams);
    const generalParams = useSelector((state) => state.generalParams)

    const dispatch = useDispatch()

    const checkPleLand = (paramsObj) => {

        let urlUpParam = paramsObj && paramsObj['up']; // Получаем параметр up из paramsObj

        // console.log(urlUpParam, cookieValues['up'])
        urlUpParam === "1" && !initPreLandState && dispatch(switchPreland()); // параметр 1 - включаем пре
        urlUpParam === "2" && initPreLandState && dispatch(switchPreland()); // параметр 2 - пропуск пре
        urlUpParam === undefined && // нет параметра
            // но есть противоположная к initPreLandState куки
            ( (cookieValues['up'] === '1' && !initPreLandState) || (cookieValues['up'] === '2' && initPreLandState) )
                && dispatch(switchPreland()) // управляем по куки

        // обновить куки
        let newUP = urlUpParam !== undefined // есть параметр
            ? urlUpParam
            : ['1','2'].includes(cookieValues['up'] ?? '0') // есть правильная кука
                ? cookieValues['up']
                : initPreLandState === true // по параметру преленда
                    ? '1'
                    : '2';
        writeCookie('up', newUP, 7);
        delParams();
    }

    const handlePixel = (paramsObj, metrics, paramKey, cookieKey) => {
        if (checkParam(paramsObj, paramKey)) {
            const value = paramsObj[paramKey];
            updateCookie(cookieKey, String(value));
            urlParams[cookieKey] = value;
            metrics[`metric${cookieKey.toUpperCase()}`] = true;
        } else if (checkParam(cookieValues, cookieKey)) {
            urlParams[cookieKey] = cookieValues[cookieKey];
            metrics[`metric${cookieKey.toUpperCase()}`] = true;
        }
    }

    const handleOtherParams = (paramsObj, paramKey, cookieKey, cookieName) => {
        if (checkParam(paramsObj, paramKey)) {
            const value = paramsObj[paramKey];
            updateCookie(cookieKey, String(value));
            urlParams[cookieName] = value;
        } else if (checkParam(cookieValues, cookieKey)) {
            urlParams[cookieName] = cookieValues[cookieKey];
        }
    }

    const handleGeneralParams = (paramsObj, paramKey, cookieKey, cookieName) => {
        if (checkParam(paramsObj, paramKey)) {
            const value = paramsObj[paramKey];
            updateCookie(cookieKey, String(value));
            generalParams[cookieName] = value;
        } else if (checkParam(cookieValues, cookieKey)) {
            generalParams[cookieName] = cookieValues[cookieKey];
        }
    }

    const checkParamsExistence = (urlParams, paramsToCheck) => {
        return paramsToCheck.some(key => urlParams[key] && urlParams[key] !== '0' && urlParams[key] !== 0 && urlParams[key] !== false)
            || paramsToCheck.some(key => cookieValues[key] && cookieValues[key] !== '0');
    }

    // returns false if not fount name of paramNames in params
    const checkGeoParams = (paramNames) => {
        return  paramNames.every(paramName => generalParams.hasOwnProperty(paramName) && generalParams[paramName] !== 'false') ||
                paramNames.every(paramName => cookieValues.hasOwnProperty(paramName) && cookieValues[paramName] !== 'false');
    }


    const setLanguage = (paramsObj, setTranslateFromUrl) => (
        (setTranslateFromUrl) // ставим язык из юрл если он есть и поддерживается
            ? paramsObj.sl.toLowerCase() ///
            : cookieValues.language2 !== undefined && langListSupported.includes(cookieValues.language2.toLowerCase()) // ставим язык браузера из апи, если он пришел и поддерживается
                ? cookieValues.language2.toLowerCase() ///
                : generalParams.getCountry2 !== undefined && langListSupported.includes(generalParams.getCountry2.toLowerCase()) // ставим язык по стране из (апи или куки) если пришел и поддерживается
                    ? generalParams.getCountry2.toLowerCase() ///
                    : defaultLang // ставим язык по умолчанию
    );

    return {handlePixel, handleOtherParams, handleGeneralParams, checkParamsExistence, checkPleLand, checkGeoParams, setLanguage}
}