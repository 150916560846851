import {
  PARAMS_TO_STORE,
  METRICS_TO_STORE,
  CHAT,
  FORM,
  QUIZ_ANSWERS,
  QUIZ_REBOOT,
  GENERAL_TO_STORE,  SWITCH_FORM_LOADER, SWITCH_APP_LOADER, SWITCH_PRELAND,
} from './types';
import { combineReducers } from 'redux';
import {flag2, initPreLandState, defaultLang} from '../store/config';

const initialStatePreLand = {
  show: initPreLandState,
};
export function PreLandReducer(state = initialStatePreLand, action) {
  switch (action.type) {
    case SWITCH_PRELAND:
      return { ...state, show: !state.show };
    default:
      return state;
  }
}

const initialStateParams = {
  g: '0',
  gt: '0',
  f: '0',
  t: '0',
  bi: '0',
  description: '0',
  buyer: '0',
  fbclid: '0'
};

export function UrlParamsReducer(state = initialStateParams, action) {
  switch (action.type) {
    case PARAMS_TO_STORE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const generalParams = {
  sl: false,
  paramsExists: false,
  language2: defaultLang,
  flag2: flag2,
  getCountry2: 'false',
  getCity: 'false',
  getCountryName: 'false',
  getStateProv: 'false',
  gtmOffset: 'false',
  w_ref: 'false',
  w_src: 'false'
}
export function generalParamsReducer(state = generalParams, action) {
  switch (action.type) {
    case GENERAL_TO_STORE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}


const initMetricsParams = {
  metricG: false,
  metricF: false,
  metricT: false,
  metricBI: false
};
export function MetricsParamsReducer(state = initMetricsParams, action) {
  switch (action.type) {
    case METRICS_TO_STORE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

//QUIZ
const initQuizValue = {
  param: {},
};

export function quizParamsReducer(state = initQuizValue, action) {
  switch (action.type) {
    case QUIZ_ANSWERS:
      return { ...state, ...action.payload };
    case QUIZ_REBOOT:
      return initQuizValue;
    default:
      return state;
  }
}

//CHAT
const initChat = {
  show: false,
};

export function chatParamsReducer(state = initChat, action) {
  switch (action.type) {
    case CHAT:
      return { ...state, show: action.payload };
    default:
      return state;
  }
}

//Form
const initForm = {
  show: false,
};

export function formParamsReducer(state = initForm, action) {
  switch (action.type) {
    case FORM:
      return { ...state, show: action.payload };
    default:
      return state;
  }
}

const initLoading = {
  appLoader: true,
  formLoader: false
};
export function LoadingReducer(state = initLoading, action) {
  switch (action.type) {
    case SWITCH_APP_LOADER:
      return { ...state, appLoader: !state.appLoader };
    case SWITCH_FORM_LOADER:
      return { ...state, formLoader: !state.formLoader };
    default:
      return state;
  }
}

export const rootReducer = combineReducers({
  preland: PreLandReducer,
  urlParams: UrlParamsReducer,
  metricsParams: MetricsParamsReducer,
  quizAnswers: quizParamsReducer,
  chat: chatParamsReducer,
  form: formParamsReducer,
  loading: LoadingReducer,
  generalParams: generalParamsReducer
});
