import {AccessForm} from "../Form/AccessForm";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
export const AccessPage = (props) => {
    const { t } = useTranslation(); //
    return (
        <div className="access-page d-flex justify-content-center align-items-center">
            <Container>
                <Row className="wrapper-access">
                    <Col lg={9}>
                        <h1 className="access-title">{t('ap_h1')}</h1>
                        <div className="access-form">
                            <AccessForm/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}