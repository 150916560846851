import {
    GENERAL_TO_STORE,
    LANG_TO_STORE,
    METRICS_TO_STORE,
    PARAMS_TO_STORE,
    QUIZ_ANSWERS, QUIZ_REBOOT,
    SWITCH_APP_LOADER,
    SWITCH_FORM_LOADER,
    SWITCH_PRELAND,
} from './types';
import axios from 'axios';
import Swal from 'sweetalert2';
import {buildModeCl, defaultLang, formQueryURL, get_ip_QueryURL, langListSupported} from '../store/config';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import i18n from "i18next";
import {isSupportedCountry} from "react-phone-number-input";
import {setCookie} from "../utils/cookie";
import UETPWASDK from "@bing-ads/uet-pwa-sdk";

export const getCountryIso = async (dispatch, setTranslateFromUrl, generalParams, paramsObj) => {
    try {
        const response = await axios.post(get_ip_QueryURL);
        const json = await response.data;
        let newGeneral = {};
        // успешный запрос
        if (json.status === 'success') {
            ///страна ISO-2
            if (json.country) {
                setCookie('getCountry2', json.country, 100);
                newGeneral = {...newGeneral, getCountry2: json.country};
            }
            ///флаг
            let l = json.country.toUpperCase()
            if (isSupportedCountry(l)) {
                await dispatch(setGeneralParams({flag2: l}));
            }
            /// нейм страны
            if (json.country_name !== undefined) {
                setCookie('getCountryName', json.country_name, 100);
                newGeneral = {...newGeneral, getCountryName: json.country_name};
            }
            /// нейм города
            if (json.city !== undefined) {
                setCookie('getCity', json.city, 100);
                newGeneral = {...newGeneral, getCity: json.city};
            }
            /// часовой пояс
            if (json.gtm_offset !== undefined) {
                setCookie('gtmOffset', json.gtm_offset, 100);
                newGeneral = {...newGeneral, gtmOffset: json.gtm_offset};
            }
            /// getStateProv
            if (json.state_prov !== undefined) {
                setCookie('getStateProv', json.state_prov, 100);
                newGeneral = {...newGeneral, getStateProv: json.state_prov};
            }
        }

        ///язык
        let ll = setTranslateFromUrl // ставим язык из юрл если он есть и поддерживается
            ? paramsObj.sl.toLowerCase() ///
            : (json.language2 !== undefined && langListSupported.includes(json.language2.toLowerCase())) // ставим язык браузера из апи, если он пришел и поддерживается
                ? json.language2.toLowerCase() ///
                : json.country !== undefined && langListSupported.includes(json.country.toLowerCase())  // ставим язык по стране из апи, если пришла и поддерживается
                    ? json.country.toLowerCase()
                    : generalParams.getCountry2 !== undefined && langListSupported.includes(generalParams.getCountry2.toLowerCase()) // ставим язык по стране из стейта\куки если она там есть и поддерживается
                        ? generalParams.getCountry2.toLowerCase()
                        : defaultLang; // ставим язык по умолчанию
        await i18n.changeLanguage(ll);
        newGeneral = {...newGeneral, language2: ll};
        ///
        await dispatch(setGeneralParams(newGeneral));


    } catch (err) {

    }
};

export async function sendFormQuery(data, textLoading) {

    /// доп окно ожидания
    await Swal.fire({
        html: textLoading,
        icon: 'success',
        confirmButtonText: "OK",
        allowOutsideClick: false,
        preConfirm: () => {
            return false
        },
        didOpen: async () => {
            await Swal.showLoading()
            const response = await axios.post(formQueryURL, data);
            const json = await response.data;
            if (json.status === 'success') {
                if (data.metricG) {
                    const {gtag} = require('ga-gtag');
                    gtag('event', 'conversion', {
                        send_to: data.g + '/' + data.gt,
                        method: 'Google',
                    });
                    console.log('send to google');
                }
                if (!buildModeCl && data.metricF) {
                    ReactPixel.track('Lead');
                    console.log('send to meta');
                }
                if (data.metricT) {
                    TiktokPixel.track('SubmitForm');
                    console.log('send to TikTok');
                }
                if (data.metricBI) {
                    const uet = new UETPWASDK({ ti: data.bi });
                    uet.trackGoal("click", "lead", "lead", 1, "USD").then(r => {});
                }
            }
            let icon = json.status === 'success' ? 'success' : 'error';
            let redirect = json.redirect;
            Swal.fire({
                html: json.message,
                icon: icon,
                confirmButtonText: 'OK',
            }).then(() => {
                if (typeof redirect === 'string') {
                    window.location.assign(redirect);
                }
            });
        }
    });
}

export function setInitMetrics(params) {
    return {
        type: METRICS_TO_STORE,
        payload: params,
    };
}

export function URLParamsToStore(params) {
    return {
        type: PARAMS_TO_STORE,
        payload: params,
    };
}

export function setGeneralParams(params) {
    return {
        type: GENERAL_TO_STORE,
        payload: params,
    };
}

export function setLang(params) {
    return {
        type: LANG_TO_STORE,
        payload: params,
    };
}

export function quizSetValue(params) {
    return {
        type: QUIZ_ANSWERS,
        payload: params,
    };
}

export function rebootQuizState() {
    return {
        type: QUIZ_REBOOT
    }
}

export function switchFormLoader() {
    return {
        type: SWITCH_FORM_LOADER,
    };
}

export function switchAppLoader() {
    return {
        type: SWITCH_APP_LOADER,
    };
}

export const switchPreland = () => ({
    type: SWITCH_PRELAND
});
