import {useRef, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {NavBar} from '../Header/NavBar';
import {css} from "aphrodite";
import {animeStyle} from "../../store/anime";
import {useSelector} from "react-redux/es/exports";
import Quiz from "../Quiz/Quiz";

import verified from '../../img/verified.webp'
import m_bg from '../../img/bg_mob-v2.webp'
export const Main = () => {

    const [open, setOpen] = useState(true);
    const quizCompletedState = useSelector((state) => state.quizAnswers.quizCompleted)

    /// делаем реф
    const animeRef = useRef();

    const buTTonHandler = () => {
        animeRef.current.classList.add(css(animeStyle.animeOut))
        ///исполняем действие по таймауту выполнения анимации
        setTimeout(() => {
            /// удаляем стили выхода из реф
            animeRef.current.classList.remove(css(animeStyle.animeOut))
            setOpen(false)
        }, 600)
    }

    function generateRandomNumber(min, max) {
        const date = new Date();
        const seed = date.toISOString().slice(0, 10); // Используем дату как начальное зерно
        const seedNumber = parseInt(seed.replace(/-/g, ''), 10);
        return (seedNumber % (max - min + 1)) + min;
    }
    const randomNumber = generateRandomNumber(3000, 5500);
    function gPlace() {
        const currentDate = new Date();
        const currentHour = currentDate.getHours();
        const initialNumber = 11;
        if (currentHour >= 18) {
            return 4;
        } else {
            const timeRemaining = 18 - currentHour;
            const decreaseRate = 1;
            const decreasedNumber = Math.max(initialNumber - timeRemaining * decreaseRate, 4);
            return Math.floor(decreasedNumber);
        }
    }
    const places = gPlace();

    return (
        <>
            {!open || quizCompletedState ? (
                <Quiz/>
            ) : (
                <>
                    <div ref={animeRef}>
                        <NavBar/>
                        <div className={`bg bg-1`}>
                            <div className={`main-content main-content-1`}>
                                <img src={m_bg} className="img-bg" alt=""/>
                                <Container fluid>
                                    <Row className="justify-content-end">
                                        <Col lg="5" md="12" className={`right-colum gradient-dark gradient-dark-1`}>
                                            <div className={`offer offer-1`}>
                                                <div className={`start`}>
                                                    <div className="page__line"></div>
                                                    <h1>Quanto puoi guadagnare con gli investimenti? 👇</h1>
                                                    <p className="page__subheader">
                                                        PARTECIPA AL PROGETTO UFFICIALE DI ENI INVESTIMENT {' '}<br/>
                                                        E ACCEDI ALLA PIATTAFORMA DI TRADING DI!
                                                    </p>
                                                    <button
                                                        className={`btn-offer btn-offer-1`}
                                                        onClick={buTTonHandler}
                                                    >
                                                        <i className="fa-regular fa-circle-check"></i>
                                                        &nbsp;Ottieni l'accesso
                                                    </button>
                                                    <div className={"form-place mt-3  text-white"}>
                                                        <h5 className={"fw-bold"} style={{marginLeft:"2rem"}}>Informazioni importanti:</h5>
                                                        <ul className={""} >
                                                            <li>Guadagno medio di un utente registrato per settimana: <span className={"fw-bold"}>€ {randomNumber}</span></li>
                                                            <li>Si stanno registrando sulla piattaforma proprio ora: <span className={"fw-bold"}>{places}</span></li>
                                                            <li><span className={"fw-bold"}>Affrettatevi</span>, i posti sono limitati</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p className={`footer footer-1`}>
                                                    <img src={verified} alt=""/>
                                                    Sito ufficiale di <b>Eni Investment</b>
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
