import {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import {isValidPhoneNumber} from 'react-phone-number-input';
import {blackListCountryPhones} from '../../store/config';
import 'react-phone-number-input/style.css';
import {sendFormQuery, setGeneralParams, switchFormLoader} from '../../redux/actions';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux/es/exports';
import Swal from 'sweetalert2';
import {PolicyPrivacy} from '../Modal/PolicyPrivacy';
import {Circles} from "react-loader-spinner";
//
export const Form = () => {
    const {t} = useTranslation(); //

    const generalParams = useSelector((state) => state.generalParams)
    const urlParams = useSelector((state)=> state.urlParams);
    const quizAnswers = useSelector((state)=> state.quizAnswers);
    const metricsParams = useSelector((state)=> state.metricsParams);
    const formLoader = useSelector((state) => state.loading.formLoader)

    const dispatch = useDispatch();

    const [value] = useState();
    const [country, setCountry] = useState(generalParams.flag2);

    const [polChecked, setPolChecked] = useState(true);
    const [PolicyPrivacyShow, setPolicyPrivacyShow] = useState(false);
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        reset,
        clearErrors,
    } = useForm({
        mode: 'onSubmit',
    });

    const setFlag = (country) => {
        dispatch(setGeneralParams({flag2: country}))
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const fakeSend = () => {
        let icon = 'success';
        Swal.fire({
            text: t('fakeRequestSent'),
            icon: icon,
            confirmButtonText: 'OK',
        }).then((r) => (r) => {
            return true;
        });
    };

    const onSubmit = (data) => {
        dispatch(switchFormLoader());
        let asArray = Object.entries(urlParams);
        let filtered = asArray.filter(([key, value]) => value !== '0');
        let newUrlParams = Object.fromEntries(filtered);
        const textLoading = t('apiLoading') ?? "Please wait...";
        let obj = {
            ...data,
            ...metricsParams,
            ...generalParams,
            ...newUrlParams,
        };
        obj['quiz'] = Object.values(quizAnswers.param).toString();
        if (
            typeof obj.country === 'string' &&
            blackListCountryPhones.includes(obj.country)
        ) {
            fakeSend();
            reset();
            return false;
        }

        sendFormQuery(obj, textLoading).then(r => {
            dispatch(switchFormLoader());
        });
        reset();
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="registration-form"
            id="form"
        >
            <div className="form__item">
                <label className="form-laber">Inserisci un nome</label>
                <div className="form-input">
                    <i className="fa-solid fa-user" />
                    <input
                        placeholder={t('placeholder.name')}
                        {...register('firstname', {
                            required: t('formMessages.required'),
                            maxLength: {
                                value: 35,
                                message: t('formMessages.maxlength'),
                            },
                            minLength: {
                                value: 2,
                                message: t('formMessages.minlength.name'),
                            },
                            pattern: {
                                value:
                                    /^[a-яa-záéíñóúüàèìîòùäößẞæøåØąćęłńśźżăâșțôëêïçûÿœ '’-]+$/i,
                                message: t('formMessages.regex.name'),
                            },
                        })}
                    />
                </div>
                {errors?.firstname && (
                    <label className={`form__error form__error-1`}>{errors.firstname.message}</label>
                )}
            </div>
            <div className="form__item">
                <label className="form-laber">Inserisci il cognome</label>
                <div className="form-input">
                    <i className="fa-solid fa-user" />
                    <input
                        placeholder={t('placeholder.lastname')}
                        {...register('lastname', {
                            required: t('formMessages.required'),
                            maxLength: {
                                value: 35,
                                message: t('formMessages.maxlength'),
                            },
                            minLength: {
                                value: 2,
                                message: t('formMessages.minlength.name'),
                            },
                            pattern: {
                                value:
                                    /^[a-яa-záéíñóúüàèìîòùäößẞæøåØąćęłńśźżăâșțôëêïçûÿœ '’-]+$/i,
                                message: t('formMessages.regex.name'),
                            },
                        })}
                    />
                </div>
                {errors?.lastname && (
                    <label className={`form__error form__error-1`}>{errors.lastname.message}</label>
                )}
            </div>
            <div className="form__item">
                <label className="form-laber">Inserisci Email</label>
                <div className="form-input">
                    <i className="fa-solid fa-envelope" />
                    <input
                        placeholder={t('placeholder.email')}
                        type="email"
                        {...register('email', {
                            required: t('formMessages.required'),
                            minLength: {
                                value: 5,
                                message: t('formMessages.minlength.email'),
                            },
                            pattern: {
                                value: /^[0-9a-z_\-.]+@[0-9a-z_\-^.]+\.[a-z]{2,30}$/i,
                                message: t('formMessages.regex.email'),
                            },
                        })}
                    />
                </div>
                {errors?.email && (
                    <label className={`form__error form__error-1`}>{errors.email.message}</label>
                )}
            </div>
            <div className="form__item item-null">
                <label className="form-laber">Inserisci il telefono</label>
                <PhoneInputWithCountry
                    // international
                    defaultCountry={country}
                    placeholder={t('placeholder.phone')}
                    onCountryChange={(country) => setFlag(country)}
                    initialValueFormat="national"
                    value={value}
                    name="phone"
                    control={control}
                    rules={{
                        required: t('formMessages.required'),
                        validate: isValidPhoneNumber,
                    }}
                    type="tel"
                />
                {errors?.phone && (
                    <label className={`form__error form__error-1`}>
                        {errors?.phone?.message || t('formMessages.phone')}
                    </label>
                )}
            </div>

            <div className="checkbox">
                <input
                    type="checkbox"
                    id="politica"
                    required="required"
                    onChange={() => setPolChecked(!polChecked)}
                    defaultChecked={polChecked}
                    {...register('politica', {
                        required: true,
                    })}
                />
                <label htmlFor="politica">{t('form.ch_b')}&nbsp;</label>
                <a href={"#"} className={`link-politica`} onClick={() => setPolicyPrivacyShow(true)}>
                    privacy policy
                </a>
            </div>
            <PolicyPrivacy
                show={PolicyPrivacyShow}
                onHide={() => setPolicyPrivacyShow(false)}
            />

            <div className="form__item d-flex justify-content-center">
                {/*{!formLoader ? (*/}
                <button className={`btn-form`} type=" submit">
                    <i className=" fa-regular fa-circle-check"></i>
                    {t('form-btn')}
                </button>
                {/*) : (*/}
                {/*    <div className="btn-form d-flex justify-content-center align-items-center">*/}
                {/*        <Circles*/}
                {/*            height="40"*/}
                {/*            width="40"*/}
                {/*            color="#ed596f"*/}
                {/*            ariaLabel="circles-loading"*/}
                {/*            wrapperStyle={{}}*/}
                {/*            wrapperClass=""*/}
                {/*            visible={true}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
            <div className="center-m">Inizia con un minimo di<span> </span>
                <span className={`form__error form__error-1`} style={{textDecoration: "line-through"}}>€350</span><span> </span>
                <span className={`form__text form__text-1`}>€200</span>
            </div>
            <div className={"center-m fw-bold mt-1 "}><span>Affrettatevi, i posti sono limitati</span></div>
        </form>
    );
};

// const mapStateToProps = (state) => {
//     return {
//         urlParams: state.urlParams,
//         metricsParams: state.metricsParams,
//         loading: state.loading.loading,
//         quizAnswers: state.quizAnswers,
//         lang: state.lang,
//     };
// };
// const mapDispatchToProps = {
//     sendFormQuery,
// };
// export default connect(mapStateToProps, mapDispatchToProps)(Form);
