import {BrowserRouter, Route, Routes} from 'react-router-dom';
import React from 'react';

import {useSelector} from "react-redux/es/exports";
import {Process} from "./Process";
//Components
import {LandingPage} from './component/Pages/LandingPage';
import {Loading} from './component/Lang/Loading';
import {CookiePage} from "./component/Pages/CookiePage";
import {PolicyPage} from "./component/Pages/PolicyPage";
import {ContactsPage} from "./component/Pages/ContactsPage";
import {AccessPage} from "./component/Pages/AccessPage";
import ScrollTopNav from "./hook/ScrollTopNav";


export const App = () => {
    const isLoading = useSelector((state) => state.loading.appLoader);
    const PreLand = useSelector((state) => state.preland.show);

    if (isLoading) {
        return (
            <>
                <Process/>
                <Loading/>
            </>
        )
    }

    return (
        <>
            <BrowserRouter>
                <ScrollTopNav/>
                <Routes>
                    {PreLand === true ? (
                        <>
                            <Route path="/" element={<AccessPage/>}/>
                        </>
                    ) : (
                        <>
                            <Route path="/" element={<LandingPage/>}/>
                            <Route path="/cookie" element={<CookiePage/>}/>
                            <Route path="/policy" element={<PolicyPage/>}/>
                            <Route path="/contacts" element={<ContactsPage/>}/>
                        </>
                    )
                    }
                </Routes>
            </BrowserRouter>
        </>
    );
}
