import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import {quizSetValue} from '../../redux/actions';
import LastStep from './LastStep';
import {useSelector} from "react-redux/es/exports";
import {animeStyle} from "../../store/anime";
import {css} from 'aphrodite';
import Icon from "@mdi/react";
import {mdiSlashForward} from "@mdi/js";
import {Main} from "../Section/Main";
import {useTranslation} from "react-i18next";

import {questionsArray} from "../../store/questions"
import q from "../../img/7.webp";
import a from "../../img/1.webp";
import b from "../../img/2.webp";
import c from "../../img/3.webp";
import d from "../../img/4.webp";
import e from "../../img/5.webp";
import f from "../../img/6.webp";
import useImagePreloader from "../../hook/ImagePreloader";
import {Loading} from "../Lang/Loading";
import {getCookie} from "../../utils/cookie";

const imgDictionary = {
    'q' : q,
    'a' : a,
    'b' : b,
    'c' : c,
    'd' : d,
    'e' : e,
    'f' : f,
};

const Quiz = (props) => {
    const {imagesPreloaded, preloadedImages} = useImagePreloader(imgDictionary) /// bool and obj with keys-vals
    const {t} = useTranslation(); //
    const parse = require('html-react-parser');
    //какая то хуета
    const [currentQuestion, setCurrentQuestion] = useState(0);
    // const [showScore, setShowScore] = useState(false);
    //квиз пройден
    const quizCompletedState = useSelector((state) => state.quizAnswers.quizCompleted)
    // const questions = text('questions') // взять из вариаций
    const lg= getCookie('i18next');
    const questions = questionsArray[lg] ?? questionsArray['en']; //взять из массива вопросов
    /// делаем реф
    const animeRef = useRef(); // для главного контейнера карточки вопроса
    /// parse string to html
    // const svg = parse(questions[currentQuestion].svg);
    //настроечки
    const animeOnExamFail = false;
    //переменная экзаменации
    const examinationFailed = props.quizAnswers.examFailed;
    ///очищаем все стили анимации в рефах
    const clearClassesOfAnimeRefs = () => {
        animeRef.current.classList.remove(...animeRef.current.classList)
    }
    // на шаг назад в квизе
    const handleBackStepClick = () => {
        handleAnswerClick(false, true, true)
    }
    // обработка действия в квизе
    const handleAnswerClick = (id, isCorrect = true, backStep = false) => {
        //isExam
        if (questions[currentQuestion].isExam) {
            if (!animeOnExamFail) { //без аниме
                !isCorrect && props.quizSetValue({examFailed: true})
            } else { //аниме
                // TODO: написать
                !isCorrect && props.quizSetValue({examFailed: true})
            }
            if (!isCorrect) {
                return
            }
        }
        ///очищаем все стили анимации в рефах
        clearClassesOfAnimeRefs();
        /// пропсы
        let data = props.quizAnswers.param;
        let nextQuestion = !backStep ? currentQuestion + 1 : currentQuestion - 1;
        // let param = !backStep ? [...data, id] : [...data.slice(0, -1)];

        data[currentQuestion] = id === false ? 0 : id
        props.quizSetValue({param: data});

        ///даем стили анимации выхода в реф
        !backStep
            ? animeRef.current.classList.add(css(animeStyle.animeOut))
            : animeRef.current.classList.add(css(animeStyle.animeBackStepOut));
        setTimeout(() => {
            ///очищаем все стили анимации в рефах (стили выхода из реф)
            clearClassesOfAnimeRefs();
            /// основное действие скрипта
            if (nextQuestion < questions.length) {
                setCurrentQuestion(nextQuestion);
            } else {
                ///квиз пройден
                props.quizSetValue({quizCompleted: true})
            }
            ///ДОБАВЛЯЕМ IN ЗДЕСЬ ТОЛЬКО В КОМПАНЕНТЕ КВИЗА. чтобы была входящая анимация на элементах
            !backStep
                ? animeRef.current.classList.add(css(animeStyle.animeIn))
                : animeRef.current.classList.add(css(animeStyle.animeBackStepIn));
            /// ставим таймаут чуть меньше чем выполнение анимации чтобы не успело перерисовать без нее
        }, 600);
    };
    const country = useSelector((state) => state.urlParams.getCountryName);
    if (!imagesPreloaded)
    {
        return <Loading/>;
    }
    return (
        <>
            {quizCompletedState ? (
                <LastStep/>
            ) : (
                <>
                    {examinationFailed
                        ? (
                            <div className="bg-overflow">
                                <div className="examination-error">
                                    <div className="wrapper-error">
                                        <p>{t('quiz.wrong_country')}{' '}{country}</p>
                                    </div>
                                </div>
                                <Main/>
                            </div>
                        )
                        : (
                            <div className={css(animeStyle.animeIn)} ref={animeRef}>
                                <div className="quiz">
                                    <div className="quiz-bg">
                                        <div className="question-main">
                                            <div className="wrapper">
                                                <div className={`question-options`}>
                                                    <div className="question-menu">
                                                        {questions[currentQuestion].answerOptions.map((item) => (
                                                            <button key={item.id}
                                                                    onClick={() => handleAnswerClick(item.id, item.isCorrect)}>
                                                                {parse(item.value)}
                                                            </button>
                                                        ))}
                                                    </div>
                                                    <p className={`question-text`}>
                                                        {currentQuestion === 0
                                                            ? questions[currentQuestion].questionText+' '+country+'?'
                                                            : questions[currentQuestion].questionText}
                                                        {/*{questions[currentQuestion].questionText}*/}
                                                    </p>
                                                </div>
                                                <div className="questions-img">
                                                    <img src={preloadedImages[questions[currentQuestion].img]} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container question-footer">
                                            {currentQuestion !== 0
                                            && <div onClick={handleBackStepClick}
                                                    style={{position: 'absolute', left: '15px'}}><i
                                                className={"fa fa-arrow-circle-left fa-2x"}/></div>
                                            }
                                            <p>
                                                {/*<span style={{fontSize: "16px"}}>Progress</span>*/}
                                                <i className={`ms-1 mx-1 fa-solid fa-1x fa-my-size fa-${currentQuestion + 1}`}/>
                                                <Icon path={mdiSlashForward} size={0.9}/>
                                                {/*<i className={"fa-solid fa-slash"} style={{transform: 'rotate(100deg)'}}/>*/}
                                                <i className={`ms-1 mx-1 fa-solid fa-1x fa-my-size fa-${questions.length}`}/>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        quizAnswers: state.quizAnswers,
        lang: state.lang
    };
};
const mapDispatchToProps = {
    quizSetValue,
};
export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
