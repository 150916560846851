import {useEffect, useState} from 'react'

function preloadImage (src: string) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = function() {
            resolve(img)
        }
        img.onerror = img.onabort = function() {
            reject(src)
        }
        img.src = src
    })
}

export default function useImagePreloader(imageList: Record<string, string>) {
    const [imagesPreloaded, setImagesPreloaded] = useState(false);
    const [preloadedImages, setPreloadedImages] = useState({});
    useEffect(() => {
        let isCancelled = false;
        async function effect() {
            if (isCancelled) {
                return;
            }
            const imagesPromiseList: Promise<any>[] = [];
            const loadedImages: Record<string, string> = {};
            for (const [key, imagePath] of Object.entries(imageList)) {
                await preloadImage(imagePath);
                loadedImages[key] = imagePath;
                imagesPromiseList.push(Promise.resolve());
            }
            await Promise.all(imagesPromiseList);
            if (isCancelled) {
                return;
            }
            setPreloadedImages(loadedImages);
            setImagesPreloaded(true);
        }
        effect().then(r => {});
        return () => {
            isCancelled = true;
        };
    }, [imageList]);
    return { imagesPreloaded, preloadedImages };
}