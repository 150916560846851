import {applyMiddleware, createStore} from "redux";
import {rootReducer} from "./rootReducer";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
// import logger from "redux-logger";
import {createLogger} from "redux-logger/src";
import {reduxLoggerOn} from "../store/config";

const logger = createLogger()

export const store = createStore(
    rootReducer,
    composeWithDevTools(
        reduxLoggerOn
            ? applyMiddleware(thunk, logger)
            : applyMiddleware(thunk) //thunk чтоб dispatch async
    )
)