import Cookies from 'js-cookie';
import { useState, useCallback } from 'react';

export const useCookie = (names, initialValues) => {
    const [values, setValues] = useState(() => {
        const cookies = {};
            names.forEach((name, index) => {
                const cookie = Cookies.get(name);
                cookies[name] = cookie ? cookie : initialValues[index];
            });
        return cookies;
    });

    const updateCookie = useCallback(
        (name, newVal, options) => {
            Cookies.set(name, newVal, options);
            setValues((prevValues) => ({
                ...prevValues,
                [name]: newVal,
            }));
        },
        []
    );

    const deleteCookie = useCallback((name) => {
        Cookies.remove(name);
        setValues((prevValues) => {
            const updatedValues = { ...prevValues };
            delete updatedValues[name];
            return updatedValues;
        });
    }, []);

    return [values, updateCookie, deleteCookie];
};
