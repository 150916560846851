import {useEffect} from "react";
import {buildModeCl, flag2, langListSupported} from "./store/config";
import {useDispatch, useSelector} from "react-redux/es/exports";
import {
    getCountryIso, setGeneralParams,
    setInitMetrics,
    switchAppLoader,
    URLParamsToStore
} from "./redux/actions";

import i18n from "i18next";
import {isSupportedCountry} from "react-phone-number-input";
import ReactPixel from "react-facebook-pixel";
import {install} from "ga-gtag";
import TiktokPixel from "tiktok-pixel";
import UETPWASDK from '@bing-ads/uet-pwa-sdk';
import {useUrlParams} from "./hook/useUrlParams";
import {getParams} from "./utils/utils";


export const Process = () => {

    const dispatch = useDispatch();
    const preland = useSelector((state) => state.preland.show);
    const metricsParams = useSelector((state) => state.metricsParams);
    const urlParams = useSelector((state) => state.urlParams);
    const generalParams = useSelector((state) => state.generalParams)
    const {handlePixel, handleOtherParams, handleGeneralParams, checkParamsExistence, checkPleLand, checkGeoParams, setLanguage} = useUrlParams()

    useEffect(() => {
        async function handleURLParams() {
            const paramsObj = getParams();
            const metrics = {};
            const urlParams = {};

            handlePixel(paramsObj, metrics, 'g', 'g');
            handlePixel(paramsObj, metrics, 'gt', 'gt');
            handlePixel(paramsObj, metrics, 'f', 'f');
            handlePixel(paramsObj, metrics, 't', 't');
            handlePixel(paramsObj, metrics, 'bi', 'bi');

            handleOtherParams(paramsObj, 'fbclid', 'fbclid', 'fbclid');
            handleOtherParams(paramsObj, 'd', 'd', 'description');
            handleOtherParams(paramsObj, 'b', 'b', 'buyer');

            handleGeneralParams(paramsObj, 'getCountry2', 'getCountry2', 'getCountry2');
            handleGeneralParams(paramsObj, 'getCountryName', 'getCountryName', 'getCountryName');
            handleGeneralParams(paramsObj, 'getCity', 'getCity', 'getCity');
            handleGeneralParams(paramsObj, 'getStateProv', 'getStateProv', 'getStateProv');
            handleGeneralParams(paramsObj, 'gtmOffset', 'gtmOffset', 'gtmOffset');
            handleGeneralParams(paramsObj, 'w_ref', 'w_ref', 'w_ref');
            handleGeneralParams(paramsObj, 'w_src', 'w_src', 'w_src');

            ///нужно ли устанавливать язык из url параметра - bool
            const setTranslateFromUrl = typeof paramsObj.sl === 'string' && paramsObj.sl !== '' && langListSupported.includes(paramsObj.sl.toLowerCase());

            const paramsToCheck = ['b', 'd', 'g', 'f', 't', 'bi'];
            dispatch(setGeneralParams({sl:setTranslateFromUrl, paramsExists: checkParamsExistence(paramsObj, paramsToCheck) }));
            dispatch(URLParamsToStore(urlParams));
            dispatch(setInitMetrics(metrics));

            checkPleLand(paramsObj)

            if (!checkGeoParams(['getCountry2', 'getCountryName', 'getCity', 'getStateProv']))
            {
                await getCountryIso(dispatch, setTranslateFromUrl, generalParams, paramsObj);
            }
            else
            {
                ///устанавливаем язык
                let ll = await setLanguage(paramsObj, setTranslateFromUrl)
                await i18n.changeLanguage(ll);
                dispatch(setGeneralParams({language2: ll, flag2: isSupportedCountry(generalParams.getCountry2) ? generalParams.getCountry2 : flag2}));
            }
            dispatch(switchAppLoader())
        }

        handleURLParams().then();
    }, []);

    useEffect(() => {
        if (!buildModeCl && metricsParams.metricF) {
            decodeURIComponent(urlParams.f).split(',').forEach(function (item) {
                ReactPixel.init(item);
            });
            ReactPixel.pageView();
        }
        if (metricsParams.metricG) {
            const {install} = require('ga-gtag');
            install(urlParams.g, {send_page_view: true});
        }
        if (metricsParams.metricT) {
            TiktokPixel.init(urlParams.t);
            TiktokPixel.pageView();
        }
        if (metricsParams.metricBI) {
            const uet = new UETPWASDK({ ti: urlParams.bi });
            uet.trackGoal("click", "PageView", "PageView", 0.01, "USD").then();
        }

    }, [preland, metricsParams]);

    return null;
};