import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { defaultLang, langListSupported } from './config';

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        lng: defaultLang,
        fallbackLng: defaultLang,
        supportedLngs: [...langListSupported],
        detection: {
            order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie'],
        },
        backend: {
            loadPath: `/` + process.env.REACT_APP_ASSETS_PATH + `locales/{{lng}}/{{ns}}.json`,
        }
    }).then(r => {});

export default i18n;
