import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {HomePage} from "../Button/HomePage";
import {NavBar} from "../Header/NavBar";

export const CookiePage = (props) => {
    return (
        <>
            <NavBar styleColors={'page-color-navbar'} home={true}/>
            <div className='container pb-3 padding-page'>
                <h1 className="mt-2 bold text-center mb-4">Privacy Cookie</h1>
                <p>
                    We may use certain third party services, such as analytics companies
                    or companies delivering advertisements in our Website, which may also
                    use cookies or other technologies, and those practices and providers
                    are subject to their own policies.
                </p>
                <p>
                    Cookie (which is a small text file) is installed on the device via
                    which you visit or access the Website. The cookies shall allow Us to
                    collect information about you and your behavior, in order to improve
                    your user experience, to remember your preferences and settings, and
                    to customize and offer you products and services that may interest
                    you. Cookies may also be used to collect statistics and perform
                    analytics.
                </p>
                <p>
                    Some of the cookies We may use are session cookies, which are
                    downloaded temporarily to your device and last until you close your
                    web browser, while others are persistent cookies, which last on your
                    device after you cease browsing the Website and can be used to help
                    the Website remember you as a returning visitor when you return to the
                    Website.
                </p>
                <h4>
                    <b>Types of cookies: </b>
                </h4>
                <p>
                    The cookies We may use are classified according to their
                    functionality, as follows:
                </p>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th>Type of Cookie</th>
                        <th>Purpose</th>
                        <th>Additional Information</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Strictly Necessary Cookies</td>
                        <td>
                            These cookies are strictly necessary to enable you to navigate
                            the Website and use features you have requested. They are used
                            to provide you with Our content, products, and services that you
                            have requested. <br />
                            Such cookies are essential to help your device download or
                            stream information so that you can navigate around the Website,
                            use its features, and return to pages you have previously
                            visited.
                        </td>
                        <td>
                            These cookies collect Personal Data about you, such as user name
                            and last login date, and identify you as being logged in to the
                            Website. <br />
                            These cookies are deleted when you close your web browser
                            (session cookies).
                        </td>
                    </tr>
                    <tr>
                        <td>Functionality Cookies</td>
                        <td>
                            These cookies are used to recognize you when you return to the
                            Website and allow Us to remember your choices and preferences.
                        </td>
                        <td>
                            These cookies survive the closing of your web browser, and last
                            until their applicable expiry time.
                        </td>
                    </tr>
                    <tr>
                        <td>Performance Cookies</td>
                        <td>
                            These cookies are used to provide aggregated statistics in
                            respect of the performance of the Website and to test and
                            improve such performance, in order to provide better user
                            experience. In addition, they allow Us to carry out analytical
                            functions on the Website.
                        </td>
                        <td>
                            These cookies collect anonymized data that is not related to an
                            identified or identifiable natural person. <br />
                            These cookies are valid for varying periods; some are deleted
                            once you close your browser, while others have an indefinite
                            validity period.
                        </td>
                    </tr>
                    </tbody>
                </table>
                <p>Blocking and removal of cookies</p>
                <p>
                    You can change your browser settings to block and delete some or all
                    cookies.
                </p>

                <p>
                    Please note, however, that if you do so, some or all of the Website’s
                    features and functionalities might not perform as intended.
                </p>
                <p>
                    <b>ONLINE TRACKING NOTICE</b>
                </p>
                <p>
                    <b>
                        AT THIS TIME, THIS SERVICE DOES NOT SUPPORT DO-NOT-TRACK SIGNALS.
                    </b>
                </p>
            </div>
        </>
    );
};
