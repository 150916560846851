import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import stylse from '../../css/PolicyPrivacy.module.css';
import { domainName } from '../../store/config';
import {HomePage} from "../Button/HomePage";
import {NavBar} from "../Header/NavBar";

export const PolicyPage = (props) => {
    return (
        <>
            <NavBar styleColors={'page-color-navbar'} home={true}/>
            <div className='container pb-3 padding-page'>
                <h1 className="mt-2 bold text-center mb-4">Privacy Policy</h1>
                <p>We are committed to securing your Personal Data and your privacy.</p>
                <p>
                    This Privacy Policy has been created to inform you about how We
                    manage, collect, stores and uses the information you provide in
                    connection with Our website {domainName} (the “Website”).
                </p>
                <p>We will uphold the following principles:</p>
                <ul className={stylse.list_none}>
                    <li>
                        <p>
                            <b>
                                <u>
                                    To be transparent in respect of the collection and processing
                                    of Personal Data about you:
                                </u>
                            </b>
                        </p>
                        <p>
                            It is important to Us that you have at all times all the
                            information required for you to make educated decisions about the
                            processing of Personal Data about you. For this purpose, We will
                            make use of various techniques and measures aimed to provide you
                            with relevant information about the processing of Personal Data
                            about you, in the proper manner and timing.
                        </p>
                        <p>
                            In addition, if We find you are required to be provided with
                            specific information, We will provide it to you in the appropriate
                            time and place.
                        </p>
                        <p>
                            We are also happy to answer any questions you may have and to
                            provide you with any clarification you require, subject to legal
                            limitations. For this purpose, you can approach Us by sending an
                            email to the following address:
                            <a href={'mailto::info@' + domainName}>
                                <span>info@{domainName}</span>
                            </a>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>
                                <u>
                                    To process Personal Data about you solely for the purposes
                                    outlined in the Policy:
                                </u>
                            </b>
                        </p>
                        <p>
                            The purposes for which We may process Personal Data about you
                            include, inter alia, to make Our Website available to you and
                            provide you with the services contained therein (the “Services”),
                            to enhance the user experience in Our Website, to improve Our
                            Services (including the Website), to protect Our rights and
                            interests, to perform business and administrative activity that
                            supports the provision of Our Services to Our users, and/or to
                            uphold any legal and/or regulatory requirements.
                        </p>
                        <p>
                            In addition, We will process Personal Data about you in order to
                            understand your personal needs and preferences.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>
                                <u>
                                    To invest significant resources in order to respect your
                                    rights in connection with Personal Data about you:
                                </u>
                            </b>
                        </p>
                        <p>
                            We apply significant resources to allow you to exercise your
                            rights as a data subject. Therefore, you may approach Us any time
                            you wish to review Personal Data about you, have Us amend it,
                            erase it, cease using it for specific purposes or in general, or
                            transfer it to you or to a third party. We will fulfill your
                            wishes in accordance with the law.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>
                                <u>To secure Personal Data about you:</u>
                            </b>
                        </p>
                        <p>
                            While We cannot promise absolute protection of the Personal Data
                            about you, We can promise that We use and will continue to use a
                            wide array of means and measures aimed at ensuring the Personal
                            Data about you is secured.
                        </p>
                    </li>
                </ul>
                <p>Our Complete Privacy Policy</p>
                <h4>
                    <b>1. The Scope?</b>
                </h4>
                <p>
                    This Policy describes what kind of Personal Data the Company collects
                    about natural persons, and how it collects it, uses it, shares it with
                    third parties, secures it, processes it, etc.
                </p>
                <p>
                    In this Policy, “Personal Data” refers to any information relating to
                    an identified or identifiable natural person. An identifiable natural
                    person is one who can be identified, directly or in combination with
                    additional information that We have or that We have access to.
                </p>
                <p>
                    In this Policy, the “processing” of Personal Data refers to any
                    operation or set of operations performed on Personal Data, including
                    the collection, recording, organization, structuring, storage,
                    adaptation or alteration, retrieval, consultation, use, disclosure by
                    transmission, dissemination or otherwise making available, alignment
                    or combination, restriction, erasure, or destruction of Personal Data.
                </p>
                <p>
                    Our Services are intended for general audience and are not directed at
                    children under the age of 18. We do not knowingly collect or solicit
                    information from anyone under the age of 18 or knowingly allow such
                    persons to access our Services. If We learn that information about a
                    child was collected, We will take reasonable steps to delete such
                    information as soon as possible.
                </p>
                <h4>
                    <b>2. When Do We Collect Personal Data About You?</b>
                </h4>
                <p>
                    We collect Personal Data about you whenever you use Our Services, use
                    Our service channels, use the Website and/or contact Us. In some
                    instances, you will actively provide Us with the Personal Data, and in
                    other instances, We will collect the Personal Data about you by
                    examining and analyzing your use of Our Services and/or Our service
                    channels.
                </p>
                <h4>
                    <b>
                        3. No Obligation To Provide Personal Data To The Company And Its
                        Implications
                    </b>
                </h4>
                <p>
                    You are not obligated to provide Us with any Personal Data about you.
                    However, in some instances, not providing such Personal Data will
                    prevent Us from providing you with the Services, will prevent your use
                    of the Website, and/or will cause the malfunctioning of Our Services
                    and the Website.
                </p>
                <h4>
                    <b>4. What Personal Data About You Do We Collect?</b>
                </h4>
                <p>
                    <b>Personal Data We collect upon each browsing onto the Website:</b>
                    this information includes online activity log, traffic information
                    (including, without limitation, IP address, time of access, date of
                    access, web and mobile page(s) visited, language used, software crash
                    reports and type of browser used, information regarding the device you
                    used. Some of this information may not identify you personally, and
                    therefore not constitute Personal Data.
                </p>
                <p>
                    <b>Personal Data We receive from you:</b> any Personal Data you
                    provide to Us of your own free will when contacting Us.
                </p>
                <p>
                    <b>
                        Personal Data You specifically provide to Us for submission to
                        third-parties:
                    </b>
                    this information includes your name and email address.
                </p>
                <h4>
                    <b>
                        5. The Purposes Of The Processing Of Personal Data And Their Legal
                        Basis
                    </b>
                </h4>
                <p>
                    The Company processes your Personal Data for one or more of the
                    purposes outlined in this section and according to the appropriate
                    legal basis.
                </p>
                <p>
                    The Company will not process Personal Data about you unless there is a
                    legal basis for such processing. The legal bases according to which
                    the Company may process Personal Data about you are as follows:
                </p>
                <ul className={stylse.list_letters}>
                    <li>
                        <b>
                            You have given consent to the processing of your Personal Data for
                            one or more specific purposes.
                        </b>
                        This refers to cases where you specifically provide your details to
                        Us through the Website, so that We submit them to third parties.
                    </li>
                    <li>
                        <b>
                            Processing is necessary for the purposes of the legitimate
                            interests pursued by the Company or by a third party.
                        </b>
                        By way of example, for the purpose of improving Our Services, or for
                        the exercise or defense of legal claims.
                    </li>
                    <li>
                        <b>
                            Processing is necessary for compliance with a legal obligation to
                            which the Company is subject.
                        </b>
                        <br />
                        At any time, you may approach Us by sending a notice to the email
                        address
                        <a href={'mailto::info@' + domainName}>
                            <span>info@{domainName}</span>
                        </a>
                        , in order to receive information concerning the review performed by
                        Us. This is so you can conclude that We may process the Personal
                        Data about you on account of such processing being necessary for the
                        purposes of the legitimate interests pursued by the Company or by a
                        third party.
                    </li>
                </ul>
                <p>
                    The following list outlines the purposes for which We may process
                    Personal Data about you and the legal basis for any such processing:
                </p>

                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Purpose</th>
                        <th>Legal Basis</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>1</th>
                        <td>
                            <b>
                                In order to submit your details to third parties at your
                                request
                            </b>
                            <br />
                            We may collect Personal Data about you in order to submit it to
                            third parties, if you specifically request that.
                        </td>
                        <td>
                            You have given consent to the processing of your Personal Data
                            for one or more specific purposes.
                        </td>
                    </tr>
                    <tr>
                        <th>2</th>
                        <td>
                            <b>
                                In order to respond to your queries, requests, and/or
                                complaints
                            </b>
                            <br />
                            Processing of Personal Data about you is required in order to
                            respond to queries you have concerning your use of the Services.
                        </td>
                        <td>
                            Processing is necessary for the purpose of the legitimate
                            interests pursued by the Company or by a third party.
                        </td>
                    </tr>
                    <tr>
                        <th>3</th>
                        <td>
                            <b>
                                In order to comply with any legal obligations or judicial or
                                administrative orders
                            </b>
                            <br />
                            We process Personal Data about you in order to comply with Our
                            various legal obligations.
                        </td>
                        <td>
                            Processing is necessary for compliance with a legal obligation
                            to which the Company is subject.
                        </td>
                    </tr>
                    <tr>
                        <th>4</th>
                        <td>
                            <b>In order to improve Our Services</b> <br />
                            We may use Personal Data about you in order to improve Our
                            Services. Such processing will include, inter alia, any crash
                            logs or other malfunction reports obtained in respect of
                            Services.
                        </td>
                        <td>
                            Processing is necessary for the purpose of the legitimate
                            interests pursued by the Company or by a third party.
                        </td>
                    </tr>
                    <tr>
                        <th>5</th>
                        <td>
                            <b>In order to prevent fraud, abusive use of Our Services</b>
                        </td>
                        <td>
                            Processing is necessary for the purpose of the legitimate
                            interests pursued by the Company or by a third party.
                        </td>
                    </tr>
                    <tr>
                        <th>6</th>
                        <td>
                            <b>
                                In order to perform and maintain various activities supporting
                                the provisions of Our Services
                            </b>
                            <br />
                            Such activities include back-office functions, business
                            development activities, strategic decision-making, oversight
                            mechanisms, etc.
                        </td>
                        <td>
                            Processing is necessary for the purpose of the legitimate
                            interests pursued by the Company or by a third party.
                        </td>
                    </tr>
                    <tr>
                        <th>7</th>
                        <td>
                            <b>
                                In order to perform analysis, including statistical analysis
                            </b>
                            <br />
                            We use various analytical measures (including statistical ones)
                            to make decisions on various issues.
                        </td>
                        <td>
                            Processing is necessary for the purpose of the legitimate
                            interests pursued by the Company or by a third party.
                        </td>
                    </tr>
                    <tr>
                        <th>8</th>
                        <td>
                            <b>
                                In order to protect Our and third parties’ interests, rights,
                                and assets, including initiation, exercise, or defense of
                                legal claims
                            </b>
                            <br />
                            We may process Personal Data about you in order to protect Our
                            interests, rights, and assets, or those of third parties,
                            according to any law, regulation, or agreement, including any of
                            Our terms and conditions and policies.
                        </td>
                        <td>
                            Processing is necessary for the purpose of the legitimate
                            interests pursued by the Company or by a third party.
                        </td>
                    </tr>
                    </tbody>
                </table>
                <h4>
                    <b>6. Transfer Of Personal Data To Third Parties </b>
                </h4>
                <p>
                    The Company may also share Personal Data about you with third parties
                    that provide Us services, such as storage and hosting providers, IP
                    address information, analysis of user experience and research,
                    analytical, technical, and diagnostic services.
                </p>
                <p>
                    In addition, you may specifically request that We submit certain
                    Personal Data about you to third-party Bitcoin trading platforms. In
                    such cases, We will share the Personal Data that you will provide to
                    Us for such purpose, to such third parties, and their use of your
                    Personal Data will be subject to their own privacy policies.
                </p>
                <p>
                    The Company may also share Personal Data about you with its related
                    entities and business partners, which provide it with resources that
                    enable the Company to enrich and improve the level of services and
                    products it provides to its users.
                </p>
                <p>
                    The Company may share Personal Data about you with governmental,
                    local, official, and regulatory authorities, as well as if such
                    disclosure is required to protect Our and third parties’ interests,
                    rights, and assets, including initiation, exercise, or defense of
                    legal claims.
                </p>
                <p>
                    In addition, We may disclose Personal Data about you to potential
                    purchasers or investors of, or lenders to, the Company and/or any
                    company within the group of companies of which the Company is a part,
                    or in the event of any similar transaction (including the sale of
                    assets of the Company and/or of any company within the group of
                    companies of which the Company is a part), and/or in connection with
                    any merger, reorganization, consolidation, or bankruptcy of the
                    Company and/or any company within the group of companies of which the
                    Company is a part.
                </p>
                <h4>
                    <b>7. Retention Of Personal Data About You</b>
                </h4>
                <p>
                    The Company shall retain Personal Data about you for as long as is
                    required to fulfill the purposes of the processing of the Personal
                    Data as outlined in this Policy, or for a longer period as required
                    according to the legislation, regulation, policies, and orders that
                    apply to Us.
                </p>
                <p>
                    In order to ensure that Personal Data about you is not retained for
                    longer than is required, We periodically review Personal Data retained
                    by Us to examine whether any Personal Data can be erased.
                </p>
                <h4>
                    <b>
                        8. Transfers Of Personal Data To A Third Country Or An International
                        Organization
                    </b>
                </h4>
                <p>
                    Personal Data about you may be transferred to a third country (i.e.
                    jurisdictions other than the one you reside in) or to international
                    organizations. In such circumstances, the Company shall take
                    appropriate safeguards to ensure the protection of Personal Data about
                    you and to provide that enforceable data subject rights and effective
                    legal remedies for data subjects are available.
                </p>
                <p>
                    If you are an EEA (European Economic Area) resident, please note that
                    these safeguards and protection will be available if any of the
                    following are met:
                </p>
                <ul className={stylse.list_none}>
                    <li>
                        The transfer is to a third country or an international organization
                        that the EU Commission has decided provides an adequate level of
                        protection to the Personal Data that is transferred to it pursuant
                        to Article 45(3) of Regulation (EU) 2016/679 of the European
                        Parliament and of the Council of 27 April 2016 ("GDPR"), including
                        any transfer in accordance with the EU-US Privacy Shield Framework;
                    </li>
                    <li>
                        The transfer is according to a legally binding and enforceable
                        instrument between public authorities or bodies pursuant to Article
                        46(2)(a) of the GDPR; or
                    </li>
                    <li>
                        The transfer is in accordance with standard data protection clauses
                        adopted by the EU Commission pursuant to Article 46(2)(c) of the
                        GDPR. The clauses adopted by the EU Commission can be viewed at{' '}
                        <a href="../info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en.html">
                            https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en
                        </a>
                        .
                    </li>
                    <li>
                        You may request that the Company provide you with details concerning
                        the safeguards employed by it to protect the Personal Data about you
                        that are transferred to a third country or an international
                        organization, by sending an email to the following address:{' '}
                        <a href={'mailto::info@' + domainName}>
                            <span>info@{domainName}</span>
                        </a>
                    </li>
                </ul>
                <h4>
                    <b>9. Protection Of Personal Data About You</b>
                </h4>
                <p>
                    We implement appropriate technical and organizational measures to
                    ensure an appropriate level of security to Personal Data, taking into
                    account the risks that are presented by processing, in particular from
                    accidental or unlawful destruction, loss, alteration, unauthorized
                    disclosure of, or access to Personal Data transmitted, stored, or
                    otherwise processed.
                </p>
                <p>
                    We may be required, due to legal or other obligations outside Our
                    control, to transfer Personal Data about you to third parties, such as
                    public authorities. In such circumstances, We have limited control
                    over the level of protection provided to the Personal Data about you
                    by such third parties.
                </p>
                <p>
                    Any transfer of Personal Data via the internet cannot be fully
                    secured. Therefore, the Company cannot ensure the protection of
                    Personal Data about you when transferred via the internet to Us.
                </p>
                <h4>
                    <b>10. Links To Websites of Third Parties</b>
                </h4>
                <p>
                    The Website may provide links to websites and/or applications of third
                    parties. The Company does not control such websites and applications,
                    nor the collection and/or processing of Personal Data about you by
                    such websites and applications, and We are not responsible for such
                    websites and applications, nor for their privacy and data protection
                    policies and activities. This Policy does not apply to any actions
                    taken via such websites and/or applications.
                </p>
                <p>
                    Whenever you access such third parties' websites and/or applications,
                    We recommend that you carefully review their privacy policies prior to
                    using such websites and/or applications and prior to disclosing any
                    Personal Data about you.
                </p>
                <h4>
                    <b>11. Changes To This Policy</b>
                </h4>
                <p>
                    We may amend, from time to time, the terms of this Policy. Whenever We
                    amend this Policy, We will notify you of such amendments by publishing
                    the updated Policy on the Website. In addition, when We make
                    significant amendments to this Policy, We will strive to inform you
                    about such amendments via means of communication We believe are
                    reasonably appropriate to inform you of such amendments and by
                    publishing a notice about such amendments on the Website. Unless
                    stated otherwise, all amendments will enter into force upon
                    publication of the updated Policy on our Website.
                </p>
                <h4>
                    <b>12. Your Rights In Respect Of The Personal Data About You</b>
                </h4>
                <p>
                    Generally, you have the right to request that We provide you with
                    confirm whether Personal Data about you is being collected by us, to
                    ask to review such data, to rectify the content if applicable and to
                    erase the Personal Data no longer required by Us. You may also
                    restrict you consent to certain processing of your Personal
                    Information.{' '}
                </p>
                <p>
                    <b>If you are an EEA resident please read this section below:</b>
                </p>
                <p>
                    You are entitled to the following rights in respect of the Personal
                    Data about you. To exercise such rights, you may send a request to
                    exercise your rights to the following email address:{' '}
                    <a href={'mailto::info@' + domainName}>
                        <span>info@{domainName}</span>
                    </a>
                </p>
                <h4>
                    <b>Right of access</b>
                </h4>
                <p>
                    You have the right to receive from the Company confirmation as to
                    whether or not Personal Data about you is being processed, and, if
                    that is the case, access to the Personal Data and the following
                    information: (1) the purposes of the processing; (2) the categories of
                    Personal Data concerned; (3) the recipients or categories of
                    recipients to whom the Personal Data have been or will be disclosed,
                    in particular recipients in third countries outside the European
                    Economic Area (EEA) or international organizations; (4) if possible,
                    the envisaged period for which the Personal Data will be stored, or,
                    if not possible, the criteria used to determine that period; (5) the
                    existence of the right to request from the Company rectification or
                    erasure of Personal Data or restriction of processing of Personal Data
                    about you or to object to such processing; (6) the right to lodge a
                    complaint with a supervisory authority; (7) if the Personal Data is
                    not collected from you, any available information as to its source;
                    (8) the existence of profiling; and (9) if Personal Data is
                    transferred to a third country outside the EEA or to an international
                    organization, the appropriate safeguards relating to the transfer.{' '}
                </p>
                <p>
                    The Company shall provide a copy of the Personal Data undergoing
                    processing and may charge a reasonable fee for any further copies
                    requested by you. If you make the request by electronic means, and
                    unless otherwise requested by you, the information shall be provided
                    in a commonly used electronic form.
                </p>
                <p>
                    The right to obtain a copy of the Personal Data shall not adversely
                    affect the rights and freedoms of others. Therefore, if the request
                    harms the rights and freedoms of others, the Company may not fulfill
                    your request or do so in a limited manner.
                </p>
                <h4>
                    <b>Right to rectification</b>
                </h4>
                <p>
                    You have the right to obtain from the Company the rectification of
                    inaccurate Personal Data about you. Taking into account the purposes
                    of the processing, you have the right for incomplete Personal Data
                    about you to be completed, including by means of providing a
                    supplementary statement.{' '}
                </p>
                <h4>
                    <b>Right to erasure</b>
                </h4>
                <p>
                    You have the right to obtain from the Company the erasure of Personal
                    Data about you if one of the following grounds applies: (a) the
                    Personal Data is no longer necessary in relation to the purpose for
                    which it was collected or otherwise processed; (b) you withdraw your
                    consent on which the processing is based and there is no other legal
                    ground for the processing; (c) you object at any time, on grounds
                    relating to your particular situation, to the processing of Personal
                    Data about you that is based on the legitimate interests pursued by Us
                    or by a third party, and there are no overriding legitimate grounds
                    for the processing; (d) you object to the processing of Personal Data
                    about you for direct marketing purposes; (e) the Personal Data has
                    been unlawfully processed; or (f) the Personal Data about you has to
                    be erased for compliance with a legal obligation in European Union or
                    Member State law to which the Company is subject.
                </p>
                <p>
                    This right is not applicable to the extent that the processing is
                    necessary (a) for compliance with a legal obligation that requires
                    processing by European Union or Member State law to which the Company
                    is subject; or (b) for the establishment, exercise, or defense of
                    legal claims.
                </p>
                <h4>
                    <b>Right of restriction of processing</b>
                </h4>
                <p>
                    You have the right to obtain from the Company the restriction of
                    processing of Personal Data about you if one of the following applies:
                    (a) the accuracy of the Personal Data is contested by you, for a
                    period enabling the Company to verify the accuracy of the Personal
                    Data about you; (b) the processing is unlawful and you oppose the
                    erasure of the Personal Data about you and request the restriction of
                    its use instead; (c) the Company no longer needs the Personal Data
                    about you for the purposes of processing, but the Personal Data is
                    required by you for the establishment, exercise, or defense of legal
                    claims; (d) if the processing of Personal Data about you is necessary
                    for the purpose of the legitimate interests pursued by the Company or
                    by a third party, unless We demonstrate compelling legitimate grounds
                    for the processing that override your interests, rights, and freedoms
                    or for the establishment, exercise, or defense of legal claims; or (e)
                    if the Personal Data about you is processed for direct marketing
                    purposes, including profiling, to the extent it is related to such
                    direct marketing.
                </p>
                <p>
                    If the processing of Personal Data about you has been restricted
                    following your request, such Personal Data shall, with the exception
                    of storage, only be processed with your consent or for the
                    establishment, exercise, or defense of legal claims or for the
                    protection of the rights of another natural or legal person or for
                    reasons of important public interest of the European Union or of a
                    Member State.
                </p>
                <h4>
                    <b>Right to data portability</b>
                </h4>
                <p>
                    You have the right to receive the Personal Data about you, which you
                    have provided to the Company, in a structured, commonly used, and
                    machine-readable format, as well as the right to transmit such
                    Personal Data to another controller, if (a) the processing is based on
                    your consent or on a contract to which you are a party, and (b) the
                    processing is carried out by automated means.
                </p>
                <p>
                    In exercising your right to data portability, you have the right for
                    the Personal Data about you to be transmitted directly from the
                    Company to another controller, where technically feasible. The
                    exercise of your right to data portability is without prejudice to
                    your and the Company’s rights under your right to erasure. In
                    addition, the right to data portability shall not adversely affect the
                    rights and freedoms of others.
                </p>
                <h4>
                    <b>Right to object</b>
                </h4>
                <p>
                    You have the right to object at any time, on grounds relating to your
                    particular situation, to the processing of Personal Data about you
                    that is based on the legitimate interests pursued by the Company or by
                    a third party, including profiling based on such legitimate interests.
                    In such case, We shall no longer process the Personal Data about you,
                    unless We demonstrate compelling legitimate grounds for the processing
                    that override your interests, rights, and freedoms or for the
                    establishment, exercise, or defense of legal claims.
                </p>
                <p>
                    You have the right to object at any time to the processing of Personal
                    Data about you for direct marketing purposes, including profiling, to
                    the extent it is related to such direct marketing.
                </p>
                <h4>
                    <b>Right to withdraw consent</b>
                </h4>
                <p>
                    You may withdraw your consent provided to Us for the purpose of
                    processing Personal Data about you at any time, without affecting the
                    lawfulness of processing based on your consent before its withdrawal.
                </p>
                <p>
                    <b>Right to lodge a complaint with a supervisory authority</b>
                </p>
                <p>
                    You have the right to lodge a complaint with a supervisory authority
                    established by a Member State to protect the fundamental rights and
                    freedoms of natural persons in relation to the processing of Personal
                    Data within the European Union.
                </p>
                <p>
                    Your rights in respect of Personal Data about you as outlined in this
                    section 13 may be restricted by European Union or Member State law to
                    which the Company is subject.
                </p>
                <p>
                    We shall provide you with the information requested according to your
                    rights outlined in this section 13 without undue delay and in any
                    event within one month of receipt of the request. That period may be
                    extended by two further months where necessary, taking into account
                    the complexity and number of the requests. We shall inform you of any
                    such extension within one month of receipt of your request, together
                    with the reasons for the delay.
                </p>
                <p>
                    The information requested according to your rights outlined in this
                    section 13 shall be provided free of charge, unless stated otherwise
                    in this section 13. Where requests are manifestly unfounded or
                    excessive, in particular because of their repetitive character, We may
                    either (a) charge a reasonable fee, taking into account the
                    administrative costs of providing the information or communication or
                    taking the action requested; or (b) refuse to act on the request.
                </p>
                <p>
                    The Company may require you to provide additional information
                    necessary to confirm your identity in order to fulfill your request
                    according to your rights outlined in this section 13, if We have
                    reasonable doubts concerning the identity of the natural person making
                    the request.
                </p>
            </div>
        </>
    );
};
