import {Form} from '../Form/Form';
import {css} from "aphrodite";
import {animeStyle} from "../../store/anime";
import {useTranslation} from "react-i18next";

const LastStep = () => {

    return (
        <div className={css(animeStyle.animeIn)}>
            <div className="quiz">
                <div className="quiz-bg-form">
                    <div className={`quiz-main quiz-main-1`}>
                        <p className={`title last-title-1`}>
                            <span>Congratulazioni!</span> Eni Investment ti dà accesso alla
                            piattaforma di trading! 🎁
                        </p>
                        <p className="fs-19">
                            Lascia i tuoi dettagli di contatto per ottenere l'accesso. ✅ <br />
                            Dopo il check-in, assicurati di prendere il telefono, Il tuo account
                            manager ti chiamerà subito.
                        </p>
                    </div>
                    <div className="quiz-form">
                        <Form />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LastStep;
