import {Container, NavbarBrand} from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link, NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import ico from '../../img/icoEni.webp'
import {getParamsFroUrl} from "../../utils/utils";
export const NavBar = (props) => {

    const setActive = ({isActive}) =>
        isActive ? 'nav-link active-link' : 'nav-link';

    const {styleColors, home} = props;

    const {t} = useTranslation(); //

    const key = getParamsFroUrl();
    return (
        <>
            <Navbar expand="lg" className={`fixed-top ${styleColors}`}>
                <Container className={`nav-container nav-container-1`}>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <NavbarBrand>
                            <Link to={"/"+key} className="home-page">
                                <img src={ico} className="i_gen" style={{maxWidth: '38px'}} alt=""/>
                            </Link>
                        </NavbarBrand>
                        <Nav className={`site-navbar ms-auto navburger site-navbar-1`}>
                            { home ? (
                                <NavLink to={"/"+key} className={setActive}>
                                    Home
                                </NavLink>
                            ):(
                                <></>
                            )
                            }
                            <NavLink to={"/policy"+key} className={setActive}>
                                Politica Privacy
                            </NavLink>
                            <NavLink to={"/cookie"+key} className={setActive}>
                                Cookie Privacy
                            </NavLink>
                            <NavLink to={"/contacts"+key} className={setActive}>
                                {t('about_us.page_name')}
                            </NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};
