import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './App';
import {Provider} from 'react-redux';
import {store} from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/access-page.css'
import './css/all.css';
import './css/style.css';
import './css/versions.css';
import './css/swal.css';

import './store/i18n';
import {Loading} from './component/Lang/Loading';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <Suspense fallback={<Loading/>}>
                <App/>
        </Suspense>
    </Provider>
);
