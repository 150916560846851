import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux/es/exports";
import {switchPreland} from "../../redux/actions";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {writeCookie} from "../../utils/cookie";
import {delParams} from "../../utils/utils";
export const AccessForm = () => {
    const dispatch = useDispatch()
    const [err, setErr] = useState(false)
    const country = useSelector((state) => state.generalParams.getCountryName);
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        clearErrors,
    } = useForm({
        mode: 'onChange',
    });

    const onSubmit = (data) => {
        dispatch(switchPreland())
        delParams()
        writeCookie('up', '2', 7);
    };

    const { t } = useTranslation(); //

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="checkbox-list">
                <label className="checkbox">
                    <input type="checkbox" className="policy checkbox-input"
                           {...register('resident', {
                               required: true,
                           })}
                    />
                    <span className="checkbox-checkmark-box">
                        <span className="checkbox-checkmark"/>
                    </span>
                    {t('ap_q1_1')}{' '}{country}{' '}{t('ap_q1_2')}.
                </label>
            </div>
            <div className="checkbox-list">
                <label className="checkbox">
                    <input type="checkbox" className="policy checkbox-input"
                           {...register('immigration', {
                               required: true,
                           })}
                    />
                    <span className="checkbox-checkmark-box">
                        <span className="checkbox-checkmark"/>
                    </span>
                    {t('ap_q2')}
                </label>
            </div>
            <div className="checkbox-list">
                <label className="checkbox">
                    <input type="checkbox" className="policy checkbox-input"
                           {...register('passport', {
                               required: true,
                           })}
                    />
                    <span className="checkbox-checkmark-box">
                        <span className="checkbox-checkmark"/>
                    </span>
                    {t('ap_q3_1')}{' '}{country}{t('ap_q3_2')}
                </label>
            </div>
            <div className="checkbox-list">
                <label className="checkbox">
                    <input type="checkbox" className="policy checkbox-input"
                           {...register('bank', {
                               required: true,
                           })}
                    />
                    <span className="checkbox-checkmark-box">
                        <span className="checkbox-checkmark"/>
                    </span>
                    {t('ap_q4')}{' '}{country}.
                </label>
            </div>
            <div className="checkbox-list">
                <label className="checkbox">
                    <input type="checkbox" className="policy checkbox-input"
                           {...register('status', {
                               required: true,
                           })}
                    />
                    <span className="checkbox-checkmark-box">
                        <span className="checkbox-checkmark"/>
                    </span>
                    {t('ap_q5')}
                </label>
            </div>
            <div className="checkbox-list">
                <label className="checkbox">
                    <input type="checkbox" className="policy checkbox-input"
                           {...register('number', {
                               required: true,
                           })}
                    />
                    <span className="checkbox-checkmark-box">
                        <span className="checkbox-checkmark"/>
                    </span>
                    {t('ap_q6')}
                </label>
            </div>
            <div className="form-item">
                <p className="text-acc">{t('ap_t1')}</p>
            </div>
            <div className="form-item">
                <button onClick={()=>setErr(true)}>{t('ap_b1')}</button>
            </div>

            <div className="error">
                {!isValid && err && (
                    <p>{t('ap_e1')}</p>
                )}
            </div>
        </form>
    )
}