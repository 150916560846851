// получить все параметр url
import {getCookie} from "./cookie";
import {buildModeCl} from "../store/config";

export const getParams = () => {
    let searchParams = new URLSearchParams(
        window.location.search.substring(1)
    ).toString();
    let paramsObj = false;
    if (searchParams.length > 0) {
        paramsObj = JSON.parse(
            '{"' +
            decodeURI(searchParams)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
            '"}'
        );
    }
    return paramsObj;
}

export const getParamsFroUrl = () => {
    let currentUrl = window.location.href;
    let url = new URL(currentUrl);
    if (buildModeCl)
    {
        let key = url.searchParams.get('key')
        return key ? '?key='+key : '';
    }
    return (url.searchParams.toString() === '') ? '' : '?'+url.searchParams.toString();
}
// очистить параметры после обработки
export const delParams = () => {
    if (buildModeCl)
    {
        let currentUrl = window.location.href;
        let url = new URL(currentUrl);
        let key = url.searchParams.get('key')
        let searchParams = new URLSearchParams();
            if (key && key !== '')
            {
                searchParams.set('key', key);
            }
            else
            {
                key = getCookie('key');
                if (key && key !== '')
                {
                    searchParams.set('key', key);
                }
            }
        url.search = searchParams.toString();
        window.history.replaceState({}, document.title, url);
    }
}

//Проверка параметров на наличие
export const checkParam = (paramsObj, paramName) => {
    return typeof paramsObj[paramName] === 'string' && (paramsObj[paramName] !== '0' || paramName === 'gtmOffset');
}

export const urlDecode =  (str) => {
    return String(decodeURIComponent(str.replace(/\+/g, ' ')));
}