
//LANDING SETTINGS
import {getCookie} from "../utils/cookie";

export const title = '';
export const videoId = ''; // VIDEO ID
//
export const langListSupported = ['it']; // ['en']; //
export const defaultLang = getCookie('i18next') !== '' //Язык по умолчанию
    ? getCookie('i18next')
    : 'it';
export const flag2 = 'IT'; //Код телефоного номера ЗАГЛАВНЫМИ БУКВАМИ
export const blackListCountryPhones= ['RU', 'UA'];
export const reduxLoggerOn = false;
export const localApi = false;
export const initPreLandState = false;
export const domainName = window.location.hostname;
export const buildModeCl = (process.env.REACT_APP_BUILD_TYPE_VARIABLE === 'clo')
// console.log(process.env)

//Query
export const formQueryURL = !localApi
    ? window.location.protocol +
    '//' +
    window.location.host +
    process.env.REACT_APP_URL_START +
    'api.php'
    : 'http://localhost/land-tpl-react-api.site/api.php';
//geo_ip
export const get_ip_QueryURL = !localApi
    ? window.location.protocol +
    '//' +
    window.location.host +
    process.env.REACT_APP_URL_START +
    'get_ip.php'
    : 'http://localhost/land-tpl-react-api.site/get_ip.php';
//AUTH
export const authURL = !localApi
    ? window.location.protocol +
    '//' +
    window.location.host +
    process.env.REACT_APP_URL_START +
    'auth.php'
    : 'http://land-tpl-react-api.site/auth.php';
//FEEDBACK
export const feedbackURL = !localApi
    ? window.location.protocol +
    '//' +
    window.location.host +
    process.env.REACT_APP_URL_START +
    'feedback.php'
    : 'http://land-tpl-react-api.site/feedback.php';