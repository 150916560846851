import {StyleSheet} from "aphrodite";
import {bounceInUp, fadeOutRight, fadeInUp, fadeOutUp, fadeInDown, fadeOutDown, fadeInLeft, fadeInRight, fadeOutLeft, zoomOut} from "react-animations";


export const animeStyle = StyleSheet.create({
    animeOut: {
        animationName: fadeOutLeft,
        animationDelay: '0.2s',
        animationDuration: '0.5s'
    },
    animeIn: {
        animationName: fadeInRight,
        animationDuration: '0.5s'
    },
    animeBackStepOut: {
        animationName: fadeOutRight,
        animationDelay: '0.2s',
        animationDuration: '0.5s'
    },
    animeBackStepIn: {
        animationName: fadeInLeft,
        animationDuration: '0.5s'
    },
    logoIn: {
        animationName: bounceInUp,
        animationDuration: '0.4s'
    },
    logoOut: {
        animationName: zoomOut,
        animationDuration: '0.4s'
    }
})