import style from './css/Loading.module.css';

export const Loading = () => {
  return (
    <div className={style.loading__conteiner}>
      <></>
      <div className={style.loading__wrapper}>
        <div className={style.lds_default}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
