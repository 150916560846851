import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {HomePage} from "../Button/HomePage";
import {NavBar} from "../Header/NavBar";
import {Col, Row} from "react-bootstrap";

export const ContactsPage = (props) => {
    return (
        <>
            <NavBar styleColors={'page-color-navbar'} home={true}/>
            <div className='container pb-3 padding-page'>
                <h1 className="mt-2 bold text-center mb-4">Contacts</h1>
                <Row className="justify-content-center">
                    <Col lg={6}>
                        <table className="table table-bordered">
                            <tbody>
                            <tr>
                                <td>PEC:</td>
                                <td>
                                    <a href="mailto:support@eni-it.com">support@eni-it.com</a>
                                </td>
                            </tr>
                            <tr>
                                <td>SEDE LEGALE</td>
                                <td>
                                    Via Emilio Praga, 39, 00137 Roma
                                </td>
                            </tr>
                            <tr>
                                <td>SEDE SECONDARIA</td>
                                <td>
                                    Via Monte di Pietà, 8 - 20121 Milano
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>
        </>
    );
};
