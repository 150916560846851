/// ACTIONS
export const SEND_QUERY = 'FETCH/SEND_QUERY';

export const SWITCH_APP_LOADER = 'LOADER/SWITCH_APP_LOADER';
export const SWITCH_FORM_LOADER = 'LOADER/SWITCH_FORM_LOADER';

export const SWITCH_PRELAND = 'LOCAL/SWITCH_PRELAND';

export const PARAMS_TO_STORE = 'LOCAL/PARAMS_TO_STORE';
export const METRICS_TO_STORE = 'LOCAL/METRICS_TO_STORE';
export const GENERAL_TO_STORE = 'LOCAL/GENERAL_TO_STORE';


export const QUIZ_ANSWERS = 'LOCAL/QUIZ_ANSWERS';
export const QUIZ_REBOOT = 'LOCAL/QUIZ_REBOOT';

export const CHAT = 'LOCAL/CHAT';
export const FORM = 'LOCAL/FORM';

export const LANG_TO_STORE = 'LOCAL/LANG_TO_STORE';


